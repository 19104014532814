import { ROUTE } from '~/constants/routes'
import AuthenticationService, {
  AUTHENTICATION_STATUS
} from '~/services/AuthenticationService'
import { hasUserAccessToPage } from '~/services/router'
import { useCurrentUserStore } from '~/stores/currentUser'
import { useCustomizationStore } from '~/stores/customization'
import { useRenamingStore } from '~/stores/renaming'
import { useUserCompanyStore } from '~/stores/userCompany'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

const loadStoresAndCheckPageAccess = async (path: string) => {
  const { id, loadCurrentUser } = useCurrentUserStore()
  if (!id) {
    await loadCurrentUser()

    const { loadCompanyInfo } = useUserCompanyStore()
    await loadCompanyInfo()

    const { loadRenamings } = useRenamingStore()
    await loadRenamings()

    const { loadCustomizations } = useCustomizationStore()
    await loadCustomizations()
  }

  const user = useCurrentUserStore()

  if (!hasUserAccessToPage(user, path)) {
    return navigateTo(ROUTE.ROOT)
  }
}

export default defineNuxtRouteMiddleware(async to => {
  const { isLoggedIn, status } = AuthenticationService.isLoggedIn()

  const { resetCurrentUser } = useCurrentUserStore()

  if (isLoggedIn) {
    await loadStoresAndCheckPageAccess(to.path)
  } else {
    if (status === AUTHENTICATION_STATUS.EMAIL_NOT_VERIFIED) {
      resetCurrentUser()
      return navigateTo(ROUTE.LOGIN)
    }

    if (status === AUTHENTICATION_STATUS.TOKEN_EXPIRED) {
      try {
        await AuthenticationService.refreshAccessTokenUsingRefreshToken()
      } catch {
        useCurrentUserStore().resetCurrentUser()

        return navigateTo({
          path: ROUTE.LOGIN,
          query:
            to.fullPath === ROUTE.ROOT
              ? undefined
              : {
                  [QUERY_PARAMETERS.REDIRECT_URL]: encodeURIComponent(
                    to.fullPath
                  )
                }
        })
      }
      await loadStoresAndCheckPageAccess(to.path)
    }
  }
})
